
/*Page Loader*/
jQuery(window).on('load',
    function() {
        jQuery(".se-pre-con").fadeOut("slow");
        setTimeout(function() {
                jQuery("html").addClass("page-loaded");
            },
            200);
    });

/*Page Loader End*/
jQuery(document).ready(function() {


});


var startBrochure = () => {

    console.log("start brochure");

    jQuery("html").addClass("show-scroll-section");

    if (jQuery(window).width() <= 767) {
        var h_height = jQuery('.site-header').innerHeight();
        jQuery('#myProgress').css('top', h_height);
    }

    // Menu Start
    jQuery('.site-header__menu_icon').click(function() {
        jQuery('body').toggleClass('open-menu');
    });
    jQuery('.close-menu-icon').click(function() {
        jQuery('body').removeClass('open-menu');
    });
    jQuery('.site-header__menu .menu a').click(function() {
        jQuery('body').removeClass('open-menu');
    });
    // Menu End

    

    // spots
    jQuery('.acc_spot').each(function() {
        jQuery(this).click(function() {
            var spot_id = jQuery(this).attr('data-acc-spot');
            jQuery('.perfect_acc_result').removeClass('pr-show-acc');
            jQuery('.perfect_acc_result').removeClass('pr-start-acc');
            jQuery('#' + spot_id).addClass('pr-show-acc');
        });
    });

    if ($(window).width() >= 768) {
        gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, Draggable, SplitText);
        const sections = gsap.utils.toArray("section");
        let scrollContainer = document.querySelector(".wrapper");
        let pin_spacer_container = document.querySelector(".pin-spacer");

        // Horizontal Scroll Start
        let maxWidth = 0;
        const getMaxWidth = () => {
            maxWidth = 0;
            sections.forEach((section) => {
                maxWidth += section.offsetWidth;
            });
        };
        getMaxWidth();
        ScrollTrigger.addEventListener("refreshInit", getMaxWidth);
        let scrollTween = gsap.to(scrollContainer,
            {
                x: () => `-${maxWidth - window.innerWidth}`,
                ease: "none"
            });

        let horizontalScroll = ScrollTrigger.create({
            animation: scrollTween,
            trigger: ".wrapper",
            pin: true,
            speed: 2,
            scrub: 1,
            end: () => `+=${maxWidth}`,
            invalidateOnRefresh: true
        });

        var dragRatio = (maxWidth / (maxWidth - window.innerWidth));
        var drag = Draggable.create(".drag-proxy",
            {
                trigger: ".wrapper",
                type: "x",
                allowContextMenu: true,
                onPress() {
                    this.startScroll = horizontalScroll.scroll();
                },
                onDrag() {
                    horizontalScroll.scroll(this.startScroll - (this.x - this.startX) * dragRatio);
                }
            });

        document.querySelectorAll(".site-header__menu .menu a, .section-menu a").forEach(element => {
            element.addEventListener('click',
                function(e) {
                    e.preventDefault();
                    const id = this.getAttribute('href').split('#')[1];
                    const targetElement = document.getElementById(id)
                    const navBar = document.querySelector('.site-header');
                    const scrollToHere = (targetElement.offsetLeft - navBar.offsetWidth) *
                        (scrollContainer.scrollWidth / (scrollContainer.scrollWidth - window.innerWidth))
                    gsap.to(window,
                        {
                            scrollTo: scrollToHere,
                            duration: 2
                        });
                });
        });
        // Horizontal Scroll End


        // Parallax Image Start
        gsap.utils.toArray(".parallax-image").forEach(text => {
            gsap.timeline({
                    defaults: { ease: "none" },
                    x: "0vw",
                    scrollTrigger: {
                        trigger: text,
                        containerAnimation: scrollTween,
                        start: "left right",
                        end: "right left",
                        scrub: true,
                        invalidateOnRefresh: true
                    }
                })
                .fromTo(text, { x: 0 }, { x: -700 }, 0);
        });
        // Parallax Image End

        // Parallax Block Start
        gsap.to(".perfect__right__gray_box",
            {
                x: -400,
                ease: "none",
                scrollTrigger: {
                    trigger: ".perfect__right__gray_box",
                    containerAnimation: scrollTween,
                    scrub: true,
                }
            });
        gsap.to(".tot-10-caps-block",
            {
                x: "-250",
                ease: "none",
                scrollTrigger: {
                    trigger: ".tot-10-caps-block",
                    containerAnimation: scrollTween,
                    scrub: true,
                    invalidateOnRefresh: true
                }
            });
        // Parallax Block End


        // Text Effect Start


        gsap.utils.toArray(".heading-animation").forEach(text => {
            gsap.timeline({
                    defaults: { ease: "none" },
                    x: "20vw",
                    scrollTrigger: {
                        trigger: text,
                        containerAnimation: scrollTween,
                        start: "left right",
                        end: "right left",
                        scrub: true,
                        invalidateOnRefresh: true,
                        toggleClass: 'enable'
                    }
                })
                .fromTo(text, { x: 0 }, { x: 0 }, 0);
        });
        // Text Effect End



    }
    if (jQuery(window).width() <= 767) {
        jQuery(document).on('click',
            'a[href^="#"]',
            function(event) {
                event.preventDefault();
                jQuery('html, body').animate({
                        scrollTop: jQuery($.attr(this, 'href')).offset().top - 50
                    },
                    500);
            });
    }

    // progress bar
    gsap.to("progress",
        {
            value: 100,
            ease: "none",
            scrollTrigger: { scrub: 0.3 },
            onUpdate: self => jQuery("#myProgress-block").css("height", jQuery("#myProgress").val() + "%")
        });

}